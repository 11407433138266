import React from 'react';
import SEO from '../components/App/SEO';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import PricingComp from '../components/Pricing/Pricing'
import { graphql } from 'gatsby';
import { redirectToStore } from '../utils/redirect'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import {isMobile} from 'react-device-detect'
import downloadAppArrow from '../assets/images/download-app-arrow.svg'
import MeetingImg from '../assets/images/video/meeting.svg'
import WorldwideImg from '../assets/images/video/worldwide.svg'
import StayproductImg from '../assets/images/video/stay-product.svg'
import StreamlineImg from '../assets/images/video/stream-line.svg'
import Uiconcept from '../assets/images/video/UIConcept.webp'
import ShieldImg from '../assets/images/video/shield.webp'
import QualitiesImg from '../assets/images/video/qualities.webp'
import AudioSpeakerImg from '../assets/images/video/audio-speaker-on.svg'
import StarImg from '../assets/images/video/star.svg'
import EngagingImg from '../assets/images/video/keep-meeting.webp'
import RocketImg from '../assets/images/video/rocket.svg'
import IntegratedChatImg from '../assets/images/video/integrated-chat.svg'
import ScreenSharingImg from '../assets/images/video/screen-sharing.svg'
import MeetingControlsImg from '../assets/images/video/meeting-controls.svg'
import WaveArrowImg from '../assets/images/video/wave-arrow.svg'
import WaveArrowRightImg from '../assets/images/video/wave-arrow-right.svg'



const Video = ({data}) => {

    let isFirefox
    let isSafari
    if (typeof window !== 'undefined') {
        isFirefox = typeof InstallTrigger !== 'undefined';
        isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    }

    return (
        <Layout env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO
            postTitle="Video | BlueMail App"
            postDescription="Security and privacy at BlueMail is a top priority for our team. We use the leading industry protocols and standards to keep you and your data safe."
            postImage="/img/OG/og_image-video.png"
            postURL="video"
            postSEO
        />
        <div className='video-bkg f pt-120 pb-120'>
            <div className="container-fluid video-header">
                <div className='container video-content f'>
                    <div className='row'>
                        <div className='col-lg-7 col-md-9 col-sm-9'>
                            <h1>BlueMail <span> Video </span></h1>
                            <p className='mb-4'>For when you need to have that face to face connection. It’s time to Meet and connect with 
                                colleagues around the world.</p>                        
                            {!isMobile && !isSafari && <div className="option-item m-auto">
                                <a 
                                    href="#" 
                                    activeClassName="active"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToStore(e); 
                                        trackCustomEvent({
                                            category: "download_now_clicked",
                                            action: "download_now_clicked",
                                        });
                                }}
                                className={"default-btn"}
                                >
                                    Download Now <img style={{marginLeft: "10px"}} src={downloadAppArrow} alt="arrow download now"/><span></span>
                                </a>
                            </div>}
                            {(isMobile || isSafari) && <div className="option-item m-auto">
                                <a 
                                    href="#" 
                                    activeClassName="active"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirectToStore(e); 
                                        trackCustomEvent({
                                            category: "download_now_clicked",
                                            action: "download_now_clicked",
                                        });
                                }}
                                className={"default-btn"}
                                >
                                    Download Now <img style={{marginLeft: "10px"}} src={downloadAppArrow} alt="arrow download now"/><span></span>
                                </a>
                            </div>}
                        </div>                  
                    </div>
                </div>
            </div>
        </div>
        <div className='container pt-100 pb-100 video-content'>
            <div className='row'>
                <div className='col text-center'>
                    <h2>A <span className='underline'>Simple, Fast, & Secure</span> Video Platform right within BlueMail</h2>
                    <p>Built with remote teamwork in mind, Meet’s user-friendly design and comprehensive 
                        features are perfect for <br className="br_" /> bringing workplace collaboration and productivity to new heights. </p>
                </div>
            </div>
            <div className='row pt-3 pb-4'>
                <div className='col-lg-3 col-md-6 col-xs-12 text-center mt-5'>
                    <img src={MeetingImg} alt="meeting" />
                    <p className='normal pt-3'>Meeting</p>
                    <p className='small'>Meet 1 on 1 or conference <br /> with hundreds of participants</p>
                </div>
                <div className='col-lg-3 col-md-6 col-xs-12 text-center mt-5'>
                    <img src={WorldwideImg} alt="world wide" />
                    <p className='normal pt-3'>Worldwide</p>
                    <p className='small'>Bridge together teams <br /> worldwide</p>
                </div>
                <div className='col-lg-3 col-md-6 col-xs-12 text-center mt-5'>
                    <img src={StayproductImg} alt="stay productive" />
                    <p className='normal pt-3'>Stay Productive</p>
                    <p className='small'>Stay connected and <br /> productive on the go</p>
                </div>
                <div className='col-lg-3 col-md-6 col-xs-12 text-center mt-5'>
                    <img src={StreamlineImg} alt="stream line" />
                    <p className='normal pt-3'>Streamline</p>
                    <p className='small'>Streamline company <br /> workflow</p>
                </div>
            </div>
        </div>
        <div className='video-bkg pt-100 pb-100'>
            <div className="container-fluid video-lobby">
                <div className='container video-content f'>
                    <div className='row'>
                        <div className='col-lg-7 offset-lg-5 col-md-7 offset-md-5 col-xs-12'>
                            <h2>BlueMail’s <span> Lobby </span></h2>
                            <p>Stay connected company-wide with Lobby, BlueMail’s one stop-shop for all your company meetings. 
                                All upcoming internal meetings are displayed in one convenient location. Link-sharing between 
                                your teams is now a thing of the past and the visibility of company meetings helps improve 
                                participation and and collaboration across all departments.</p>  
                        </div>                  
                    </div>
                </div>
            </div>
        </div>
        <div className='container pt-100 pb-100 text-center video-content'>
            <div className='row'>
                <div className='col'>
                    <h2><span>Web</span> Meet <span className='underline'>Anywhere</span></h2>
                    <p className='mt-3'>Anyone you invite can easily join your meeting from their preferred desktop browser without creating an 
                        account or signing in. It’s that simple.</p>
                </div>
            </div>
            <div className='row'>
                <div className='col mt-5'>
                    <img src={Uiconcept} alt="ui concept" />
                </div>
            </div>
        </div>
        <div className='video-bkg pt-100 pb-100'>
            <div className='container video-content'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                        <h2><span>Safeguard</span> your Meetings</h2>
                        <p>Your safety is our top priority. BlueMail Video offers the most-reliable <b> AES 256-bit </b> 
                            encryption and highest industry standard security protocol to ensure your conversations 
                            stay secured.</p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                        <img className='shild' src={ShieldImg} alt="shield" />
                    </div>
                </div>
            </div>
        </div>
        <div className='container pt-100 pb-100 video-content text-center'>
            <div className='row'>
                <div className='col m-auto'>
                    <h2><span className='underline'>Qualities</span> We All Care About</h2>
                    <p className='mt-3'>Nobody likes a grainy picture. Enjoy high resolution and visual clarity as you meet new faces 
                        from around the world. </p>
                </div>                
            </div>
            <div className='row'>
                <div className='col mt-5'>
                    <img src={QualitiesImg} alt="shield" />
                </div>
            </div>
        </div>
        <div className='video-bkg'>
            <div className="container-fluid video-audio">
                <div className='container video-content'>
                    <div className='row'>
                        <div className='col-lg-7 offset-lg-5 col-md-12 col-xs-12'>
                            <img src={AudioSpeakerImg} alt="audio speaker" />
                            <h2 className='mt-3'><span>Enhanced</span> Audio</h2>
                            <p className='mt-3'>Distorted sounds can disrupt meetings and team workflow. <br className='br_' /> With background noise suppression, 
                                automatic gain control, and echo cancellation, you can expect superior and consistent audio 
                                clarity.</p>  
                        </div>                  
                    </div>
                </div>
            </div>
        </div>
        <div className='container pt-100 pb-100 video-content text-center'>
            <div className='row'>
                <div className='col m-auto'>
                    <img src={StarImg} alt="star" />
                    <h2>Keep Meetings <span className='underline'>Engaging</span></h2>
                    <p className='mt-3'>You might need to change it up once in a while. When you do, add a virtual background, 
                    screen share your <br className='br_' /> presentation, adjust webcam settings, and more to keep your audience captivated.</p>
                </div>                
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-xs-12'>
                    <div className='row pt-3 pb-4'>
                        <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4 jcs mt-4'>
                            <div className='row'>
                                <div className='col text-center'>
                                    <img className='circle' src={IntegratedChatImg} alt="integrated chat" />
                                    <p className='normal pt-3'>Integrated chat</p>
                                    <p className='small'>Stay connected and <br /> productive on the go</p>
                                    <img className='wave-left' src={WaveArrowImg} alt="screen sharing" />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4 jcc mt-4'>
                            <div className='row'>
                                <div className='col text-center'>
                                    <img src={ScreenSharingImg} alt="screen sharing" />
                                    <p className='normal pt-3'>Screen Sharing</p>
                                    <p className='small'>Stay connected and <br /> productive on the go</p>
                                    <img className='wave-center' src={WaveArrowImg} alt="screen sharing" />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4 jce mt-4'>
                            <div className='row'>
                                <div className='col text-center'>
                                    <img className='circle' src={MeetingControlsImg} alt="meeting control" />
                                    <p className='normal pt-3'>Meeting Controls</p>
                                    <p className='small'>Stay connected and <br /> productive on the go</p>
                                    <img className='wave-right' src={WaveArrowRightImg} alt="screen sharing" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col mt-5'>
                    <img className='circle_' src={EngagingImg} alt="engaging" />
                </div>
            </div>
        </div>
        <div className='video-bkg pt-100 video-content'>
            <div className='container'>
                <div className='row'>
                    <div className='col text-center'>
                        <img src={RocketImg} alt="rochet" />
                        <h2><span>Get Started</span></h2>
                        <p>BlueMail Video is available as part of our BlueMail <br className='br_' /> Business Pro plan and above</p>
                    </div>
                </div>
                <PricingComp />
            </div>            
        </div>
        <Footer />
        </Layout>
    )
}

export default Video;

export const query = graphql`
query VideoPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
